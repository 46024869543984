import * as React from 'react';
import { Helmet } from 'react-helmet';

// import 'prism-themes/themes/prism-material-light.css';
import 'prism-themes/themes/prism-a11y-dark.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import './boxy.less';

import styled from 'styled-components';

const Container = styled.div`
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
`;

const Layout = (props) => (
  <div>
    <Helmet>
      {/* <script type="module" src="/js/stl-viewer/stl-viewer.js"></script> */}
      <link
        href="https://fonts.googleapis.com/css?family=Spectral|Rubik|Anonymous+Pro"
        rel="stylesheet"
      />

      {/* favicon set from favicomatic.com */}
      <meta name="application-name" content="travisgeis.com" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="57x57"
        href="/favicons/apple-touch-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href="/favicons/apple-touch-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href="/favicons/apple-touch-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href="/favicons/apple-touch-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="60x60"
        href="/favicons/apple-touch-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href="/favicons/apple-touch-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href="/favicons/apple-touch-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href="/favicons/apple-touch-icon-152x152.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicons/favicon-196x196.png"
        sizes="196x196"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicons/favicon-96x96.png"
        sizes="96x96"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicons/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicons/favicon-16x16.png"
        sizes="16x16"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicons/favicon-128.png"
        sizes="128x128"
      />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta
        name="msapplication-TileImage"
        content="/favicons/mstile-144x144.png"
      />
      <meta
        name="msapplication-square70x70logo"
        content="/favicons/mstile-70x70.png"
      />
      <meta
        name="msapplication-square150x150logo"
        content="/favicons/mstile-150x150.png"
      />
      <meta
        name="msapplication-wide310x150logo"
        content="/favicons/mstile-310x150.png"
      />
      <meta
        name="msapplication-square310x310logo"
        content="/favicons/mstile-310x310.png"
      />
      <script type="module" src="/stl-viewer/stl-viewer.js" />
    </Helmet>
    <Container {...props} className={props.className}></Container>
    {/* <div id="footer">
      <div className="ui stackable secondary menu">
        <a href="/rss2.xml" className="item">
          <i className="rss icon"></i>
          RSS
        </a>
        <a href="/atom.xml" className="item">
          <i className="rss icon"></i>
          Atom
        </a>
      </div>
    </div> */}
  </div>
);

export default Layout;
