import React, { FC } from 'react';
import App from '../components/App';
import Page from '../components/Page';
import { Menu } from '../components/Menu';
import { graphql, Link } from 'gatsby';

import styled from 'styled-components';
import { Boxy } from '../styles';

const TitleDiv = styled.div`
  ${Boxy}
  padding: 1rem;
  margin-top: var(--title-top-margin);
  margin-bottom: 2rem;
  a:hover {
    text-decoration: none;
  }
`;

export const Titles: FC = (props) => {
  return <TitleDiv id="titles" {...props}></TitleDiv>;
};
