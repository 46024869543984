import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const Page: FC<{
  title?: string;
  date?: Date;
  style: any;
}> = ({ title, date, style, children }) => {
  return (
    <div className="page" style={style}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div id="content">{children}</div>
    </div>
  );
};

export default Page;
